





























































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Prop } from 'vue-property-decorator'
import { AppProvider, appProvider } from '@/app-providers'
import { toJS } from 'mobx'

@Observer
@Component({
  components: {}
})
export default class Sender extends Vue {
  @Provide() providers: AppProvider = appProvider
  @Prop() open!: boolean
  wallet = this.providers.wallet
}
